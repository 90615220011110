import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import en from './en.json'
import fr from './fr.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('group_tia_lang') || 'fr',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
