import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import baseLoadable from '@loadable/component'
import Loading from './components/Loader'

function loadable(func: any) {
  return baseLoadable(func, { fallback: <Loading /> })
}
interface AsyncPageProps {
  page: string
}

const AsyncPage = (props: AsyncPageProps) => {
  const Component = loadable(() => import(`./pages/${props.page}`))
  return <Component />
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AsyncPage page="Home" />} />
        <Route path="/teams" element={<AsyncPage page="Team" />} />
        <Route path="/reference" element={<AsyncPage page="Reference" />} />
        <Route path="/contact" element={<AsyncPage page="Contact" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
