import React from 'react'
import logo from '../../assets/tia-logo-214x47.png'

const Loading = () => {
  return (
    <div className="page-loader bg-gradient-animated">
      <svg
        className="page-loader-progress"
        x="0px"
        y="0px"
        width="180"
        height="180"
        viewBox="0 0 180 180"
      >
        <circle className="page-loader-circle" cx="90" cy="90" r="88"></circle>
      </svg>
      <div className="page-loader-inner active">
        <div className="page-loader-inner-side page-loader-inner-side-1">
          <img className="pended-img page-loader-logo" src={logo} alt="" width="214" height="47" />
        </div>
        <div className="page-loader-inner-side page-loader-inner-side-2">Loading...</div>
      </div>
    </div>
  )
}

export default Loading
